import styled, { css } from "styled-components/macro"
import { Link } from "gatsby"

import {
  NAV_BACKGROUND_COLOR,
  NAV_COLOR,
  BOTTOM_NAV_HEIGHT,
} from "../../constants"

const Container = styled.nav`
  height: ${BOTTOM_NAV_HEIGHT}px;
  width: 100%;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  background-color: ${NAV_BACKGROUND_COLOR};
  border-top: 1px solid rgb(214, 214, 214);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`
const NavItem = styled.li`
  width: 100%;
  height: 100%;
  margin: 0;

  border-right: 1px solid ${NAV_COLOR};

  &:last-child {
    border-right: none;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${NAV_COLOR};
    `}
`

const NavItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
`

const NavLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  text-decoration: none;
  color: ${NAV_COLOR};
  font-weight: 400;
  font-size: 10px;
  text-transform: capitalize;

  ${({ active }) =>
    active === "true" &&
    css`
      color: rgb(255, 255, 255);
      font-size: 12px;

      & path {
        fill: rgb(255, 255, 255);
      }
    `}
`

export { Container, NavItem, NavItemContainer, NavLink }
