import React from "react"

import { BottomNav, Header } from "src/components"
import { ContentContainer } from "./LayoutStyledComponents"

const Layout = props => {
  const { activeNavLink, children } = props

  return (
    <>
      <Header />
      <ContentContainer>{children}</ContentContainer>
      <BottomNav activeNavLink={activeNavLink} />
    </>
  )
}

export default Layout
