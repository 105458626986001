import styled from "styled-components/macro"

import { GOLD } from "../../constants"

const AffordableFamilyDinnerPriceContainer = styled.li`
  display: flex;
  justify-content: space-between;
  margin: 0 16px 4px 16px;
  text-transform: capitalize;
`

const AffordableFamilyDinnerPricesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 16px 0;
  padding: 0;
`

const AffordableFamilyItemContainer = styled.li`
  display: flex;
  text-transform: capitalize;
`

const AffordableFamilyItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const BeveragesHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  padding: 0 16px;
  text-transform: capitalize;
`

const Container = styled.div`
  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

const FamilyDinnerDescriptionContainer = styled.div`
  text-align: center;
  margin-top: 16px;
  color: ${GOLD};
`

const FamilyDinnerMenuItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  margin: 16px 0;
  padding: 0;
`

const FamilyDinnerMenuItemContainer = styled.li`
  display: flex;
  text-transform: capitalize;
`

const FriedRiceItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

const FriedRicesContainer = styled.ul`
  list-style-type: none;
  margin: 16px;
  padding: 0;
`

const LunchMenuItem = styled.li`
  display: flex;
  margin-bottom: 8px;
`

const LunchMenuItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 16px 0;
  padding: 0;

  ${LunchMenuItem}:last-child {
    margin: 0;
  }
`

const LunchMenuTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
`

const SectionContainer = styled.div`
  margin: 16px 12px;
  text-transform: capitalize;
`

const Text = styled.p`
  margin: 0;
  padding: 0;
`

const ChineseText = styled.span`
  padding-right: 4px;
  font-size: 18px;
  color: rgb(120, 80, 39);
`

const ChineseItemText = styled.span`
  padding-right: 4px;
  font-size: 16px;
  color: rgb(120, 80, 39);
`

export {
  AffordableFamilyDinnerPriceContainer,
  AffordableFamilyDinnerPricesContainer,
  AffordableFamilyItemContainer,
  AffordableFamilyItemsContainer,
  BeveragesHeadingContainer,
  ChineseText,
  ChineseItemText,
  Container,
  FamilyDinnerDescriptionContainer,
  FamilyDinnerMenuItemContainer,
  FamilyDinnerMenuItemsContainer,
  FriedRiceItem,
  FriedRicesContainer,
  LunchMenuItem,
  LunchMenuItemsContainer,
  LunchMenuTitleContainer,
  SectionContainer,
  Text,
}
