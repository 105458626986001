import styled from "styled-components/macro"

import { GOLD, DARK_RED } from "../../constants"

const CategoryContainer = styled.li``

const CategoryDescripionContainer = styled.div`
  margin: 16px;
`

const CategoryDescripionText = styled.p`
  font-style: italic;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  color: ${GOLD};
`

const CategoryComboText = styled.p`
  font-style: italic;
  font-weight: 400;
  text-align: center;
  color: ${DARK_RED};
`

const CategorySection = styled.section`
  width: 100%;
  margin-bottom: 16px;

  & div:nth-child(2) {
    margin-top: 16px;
  }

  ${({ chefSpecial }) =>
    chefSpecial &&
    `
    @media (min-width: 900px) {
      height: 580px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
  `}
`

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 16px 0;

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 8px;
  }
`

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-right: 8px;

  @media (min-width: 900px) {
    width: 100%;
    margin-right: 0;
  }
`

const MenuItemIdText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
`

const MenuItemPriceText = styled.p`
  margin: 0 0 0 4px;
  padding: 0;
  font-size: 18px;
  text-align: right;
  font-weight: 600;
`

const MenuItemTextContainer = styled.div`
  display: flex;
`

const MenuItemTitleText = styled.p`
  margin: 0 0 0 4px;
  padding: 0;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 400;
`

const MenuItemChineseTitleText = styled.span`
  padding-right: 4px;
  font-size: 18px;
  color: rgb(120, 80, 39);
`

const SectionHeadingContainer = styled.div`
  padding: 0 16px;
`

export {
  CategoryComboText,
  CategoryContainer,
  CategoryDescripionContainer,
  CategoryDescripionText,
  CategorySection,
  Container,
  MenuItemChineseTitleText,
  MenuItemContainer,
  MenuItemIdText,
  MenuItemPriceText,
  MenuItemTextContainer,
  MenuItemTitleText,
  SectionHeadingContainer,
}
