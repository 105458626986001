const affordableFamilyDinner = {
  title: "經 濟 和 菜 affordable family dinner",
  description:
    "served with egg treasure bean curd soup and steamed rice.\nfor each person, you can choose one of the following choice:",
  prices: [
    { chineseTitle: "四 人 和 菜", title: "dinner for four", amount: "61.95" },
    { chineseTitle: "五 人 和 菜", title: "dinner for five", amount: "76.95" },
    { chineseTitle: "六 人 和 菜", title: "dinner for six", amount: "91.95" },
    {
      chineseTitle: "七 人 和 菜",
      title: "dinner for seven",
      amount: "106.95",
    },
    {
      chineseTitle: "八 人 和 菜",
      title: "dinner for eight",
      amount: "121.95",
    },
  ],
  items: [
    {
      title: "queen yang chicken (half)",
      chineseTitle: "貴 妃 雞（半 只)",
      isSpicy: false,
    },
    {
      title: "black pepper pork chop on plate",
      chineseTitle: "鐵 板 黑 椒 豬 排",
      isSpicy: true,
    },
    {
      title: "peking pork chop",
      chineseTitle: "京 都 肉 排",
      isSpicy: false,
    },
    {
      title: "ground pork with green bean",
      chineseTitle: "干 扁 四 季 豆",
      isSpicy: false,
    },
    {
      title: "green bean with beef",
      chineseTitle: "四 季 豆 牛 肉",
      isSpicy: false,
    },
    {
      title: "deep fried intestine",
      chineseTitle: "炸 大 腸",
      isSpicy: false,
    },
    {
      title: "intestine with preserved cabbages",
      chineseTitle: "酸 菜 大 腸",
      isSpicy: false,
    },
    {
      title: "squid with black bean sauce",
      chineseTitle: "豉 汁 鱿 鱼",
      isSpicy: false,
    },
    {
      title: "duck feet in oyster sauce",
      chineseTitle: "蠔 油 鴨 掌",
      isSpicy: false,
    },
    {
      title: "chinese broccoli with beef",
      chineseTitle: "中 芥 蘭 牛 肉",
      isSpicy: false,
    },
    {
      title: "buddha vegetable and tofu pot",
      chineseTitle: "羅 漢 豆 腐 煲",
      isSpicy: false,
    },
    {
      title: "beef stew in hot pot",
      chineseTitle: "牛 腩 煲",
      isSpicy: false,
    },
    {
      title: "egg plant and shredded pork hot pot",
      chineseTitle: "魚 香 茄 子 煲",
      isSpicy: false,
    },
    {
      title: "braised tofu and vegetables",
      chineseTitle: "紅 燒 豆 腐",
      isSpicy: false,
    },
    {
      title: "assorted vegetables, meats and seafood",
      chineseTitle: "八 珍 扒 豆 腐",
      isSpicy: false,
    },
    {
      title: "preserved vegetable with chinese bacon pot",
      chineseTitle: "梅 菜 扣 肉 煲",
      isSpicy: false,
    },
    {
      title: "hong kong styled beef",
      chineseTitle: "港 式 牛 肉",
      isSpicy: false,
    },
    {
      title: "chef's special noodles",
      chineseTitle: "干 燒 伊 麵",
      isSpicy: false,
    },
    {
      title: "salty fish, lettuce & bean cured hot pot",
      chineseTitle: "咸 魚 生 菜 豆 腐 煲",
      isSpicy: false,
    },
    {
      title: "shredded pork with preserved vegetable",
      chineseTitle: "榨 菜 肉 絲",
      isSpicy: false,
    },
    {
      title: "shredded pork with preserved cabbage",
      chineseTitle: "酸 菜 肉 絲",
      isSpicy: false,
    },
    {
      title: "enoki mushrooms with beef",
      chineseTitle: "金 針 菇 牛 肉",
      isSpicy: false,
    },
    {
      title: "shredded pork with dried bean curd",
      chineseTitle: "五 香 豆 干 肉 絲",
      isSpicy: false,
    },
    {
      title: "cod fish with pickle cabbage",
      chineseTitle: "雪 菜 魚 片",
      isSpicy: false,
    },
    {
      title: "pickled cabbage and tofu pot",
      chineseTitle: "沙 窩 豆 腐 煲",
      isSpicy: false,
    },
    {
      title: "preserved sausage with chinese broccoli",
      chineseTitle: "臘 味 芥 蘭",
      isSpicy: false,
    },
    {
      title: "sautèed green onion with beef",
      chineseTitle: "蔥 爆 牛 肉",
      isSpicy: false,
    },
    {
      title: "shrimp with diced bean curd",
      chineseTitle: "麻 婆 豆 腐",
      isSpicy: true,
    },
  ],
}

const familyDinner = {
  title: "family dinner",
  priceDescription: "$17.95 per person",
  serviceDescription: "(minimum serve for two)",
  description:
    "served with ham fried rice, paper wrapped chicken, egg roll, fried shrimp, egg drop soup and choice of the following dishes",
  items: [
    { title: "pon pon chicken", isSpicy: true },
    { title: "sweet & sour chicken", isSpicy: false },
    { title: "beef with snow peas", isSpicy: false },
    { title: "sesame chicken", isSpicy: false },
    { title: "moo goo gai pan", isSpicy: false },
    { title: "sweet & sour pork", isSpicy: false },
    { title: "monolian beef", isSpicy: true },
    { title: "kung po shrimp", isSpicy: true },
    { title: "beef with garlic sauce", isSpicy: true },
    { title: "kung po chicken", isSpicy: true },
    { title: "shredded pork szechuan styled", isSpicy: true },
    { title: "vegetable delight", isSpicy: false },
    { title: "chicken with cashew nuts", isSpicy: false },
    { title: "buddha supreme", isSpicy: false },
    { title: "beef with broccoli", isSpicy: false },
    { title: "lemon chicken", isSpicy: false },
    { title: "general tso's chicken", isSpicy: true },
    { title: "ham egg foo yung", isSpicy: true },
    { title: "beef egg foo yung", isSpicy: true },
    { title: "beef with oyster sauce", isSpicy: true },
    { title: "orange chicken", isSpicy: false },
    { title: "spicy chicken", isSpicy: true },
    { title: "peking pork chop", isSpicy: false },
    { title: "shrimp with mixed vegetables", isSpicy: false },
    { title: "chicken with green bean in black bean sauce", isSpicy: false },
    { title: "beef with green bean", isSpicy: false, extra: "1" },
    { title: "hong kong style beef", isSpicy: false, extra: "1" },
    { title: "sweet and sour shrimp", isSpicy: false, extra: "1" },
    { title: "shrimp with lobster sauce", isSpicy: false, extra: "1" },
    { title: "black pepper shrimp", isSpicy: true, extra: "1" },
    { title: "walnut shrimp with salad dressing", isSpicy: true, extra: "3" },
  ],
}

const friedRice = {
  title: "fried rice",
  items: [
    {
      title: "ham fried rice",
      price: "7.35",
    },
    {
      title: "pork fried rice",
      price: "7.35",
    },
    {
      title: "chicken fried rice",
      price: "7.35",
    },
    {
      title: "beef fried rice",
      price: "7.35",
    },
    {
      title: "shrimp fried rice",
      price: "8.95",
    },
    {
      title: "yang show fried rice",
      price: "8.95",
    },
    {
      title: "eight delights on rice",
      price: "8.95",
    },
  ],
}

const lunchMenu = {
  title: "lunch menu",
  description: "monday - friday (except holiday) 11:00am - 3:00pm",
  combonations: [
    {
      title: "combination delight",
      description:
        "served with soup of the day, ham fried rice & one of our delicious appetizers:\negg roll, fried wonton, tempura shrimp, or paper wrapped chicken",
      price: "8.95",
      items: [
        {
          id: "1",
          title: "chicken / shrimp / beef / pork chow mein",
          isSpicy: false,
        },
        {
          id: "2",
          title: "szechuan style pork / chicken / beef",
          isSpicy: true,
        },
        {
          id: "3",
          title: "beef / chicken with snow peas",
          isSpicy: false,
        },
        {
          id: "4",
          title: "pepper beef steak",
          isSpicy: false,
        },
        {
          id: "5",
          title: "vegetable lo mein",
          isSpicy: false,
        },
        {
          id: "6",
          title: "cashew chicken",
          isSpicy: false,
        },
        {
          id: "7",
          title: "cashew shrimp",
          isSpicy: false,
        },
        {
          id: "8",
          title: "beef / chicken with broccoli",
          isSpicy: false,
        },
        {
          id: "9",
          title: "kung po shrimp / chicken",
          isSpicy: true,
        },
        {
          id: "10",
          title: "sweet & sour chicken / pork",
          isSpicy: false,
        },
        {
          id: "11",
          title: "mixed vegetable delight",
          isSpicy: false,
        },
        {
          id: "12",
          title: "moo goo gai pan",
          isSpicy: false,
        },
        {
          id: "13",
          title: "lemon chicken",
          isSpicy: false,
        },
        {
          id: "14",
          title: "sesame chicken",
          isSpicy: false,
        },
        {
          id: "15",
          title: "orange chicken",
          isSpicy: false,
        },
      ],
    },
    {
      title: "deluxe combination",
      description:
        "served with soup of the day, ham fried rice, egg roll & tempura shrimp",
      price: "9.55",
      items: [
        { id: "d1", title: "chicken with straw mushrooms", isSpicy: false },
        { id: "d2", title: "chicken with black bean sauce", isSpicy: false },
        { id: "d3", title: "shrimp with mixed vegetable", isSpicy: false },
        { id: "d4", title: "sweet & sour chicken / pork", isSpicy: false },
        { id: "d5", title: "shrimp with lobster sauce", isSpicy: false },
        { id: "d6", title: "chicken / beef lo mein", isSpicy: false },
        { id: "d7", title: "beef with oyster sauce", isSpicy: false },
        {
          id: "d8",
          title: "chicken / beef with mixed vegetable",
          isSpicy: false,
        },
        {
          id: "d9",
          title: "chicken with green bean in black bean sauce",
          isSpicy: false,
        },
        { id: "d10", title: "mongolian beef", isSpicy: true },
        { id: "d11", title: "twice cooked pork", isSpicy: true },
        { id: "d12", title: "pon pon chicken / pork", isSpicy: true },
        { id: "d13", title: "general tso's chicken", isSpicy: true },
        { id: "d14", title: "peking spare ribs", isSpicy: false },
        { id: "d15", title: "beef in garlic suace", isSpicy: true },
        { id: "d16", title: "szechuan style shrimp", isSpicy: true },
        { id: "d17", title: "almond chicken", isSpicy: false },
      ],
    },
  ],
}

export default {
  affordableFamilyDinner,
  familyDinner,
  friedRice,
  lunchMenu,
}
