export default {
  1: {
    title: "頭 抬 appetizer",
    description: null,
    optionId: null,
    hasCombo: false,
  },
  2: {
    title: "湯 類 soup",
    description: null,
    optionId: null,
    hasCombo: false,
  },
  3: {
    title: "牛 肉 類 beef",
    description: null,
    optionId: "1",
    hasCombo: true,
  },
  4: {
    title: "雞 肉 類 chicken",
    description: null,
    optionId: "1",
    hasCombo: true,
  },
  5: {
    title: "蝦 類 shrimp",
    description: null,
    optionId: "1",
    hasCombo: true,
  },
  6: {
    title: "豬 肉 類 pork",
    description: null,
    optionId: "1",
    hasCombo: true,
  },
  7: {
    title: "素 菜 類 vegetable",
    description: null,
    optionId: "1",
    hasCombo: false,
  },
  8: {
    title: "芙 蓉 蛋 類 egg foo yung",
    description: null,
    optionId: "1",
    hasCombo: false,
  },
  9: {
    title: "撈 面 類 lo mein",
    description: "soft stir fried noodle, egg noodles, or spaghettis noodles",
    optionId: null,
    hasCombo: true,
  },
  10: {
    title: "美 式 炒 麵 chop suey",
    description: null,
    optionId: "2",
    hasCombo: true,
  },
  11: {
    title: "炒 飯 類 fried rice",
    description: null,
    optionId: null,
    hasCombo: false,
  },
  12: {
    title: "中 式 煎 面，炒 河 粉 pan fried noodles",
    description: null,
    optionId: null,
    hasCombo: false,
  },
  13: {
    title: "中 式 湯 麵 noodle soup",
    description: null,
    optionId: null,
    hasCombo: false,
  },
  14: {
    title: "本 樓 特 菜 chef's specialties",
    description: null,
    optionId: "1",
    hasCombo: false,
  },
  15: {
    title: "combination delight",
    description:
      "served with ham fried rice, egg roll, fried shrimp and egg drop soup",
    optionId: null,
    hasCombo: false,
  },
}
