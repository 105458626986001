const beverages = {
  title: "beverages",
  items: [
    {
      title: "soft drink",
      price: "3.50",
      drinks: [
        "coke",
        "diet coke",
        "dr. pepper",
        "sprite",
        "ice tea",
        "lemonade",
      ],
    },
    {
      title: "hot tea",
      price: "2.00",
      drinks: [],
    },
  ],
}

const alcohol = [
  {
    title: "house wine",
    drinks: [
      {
        name: "merlot",
        price: "5.50",
      },
      {
        name: "cabernet",
        price: "5.50",
      },
      {
        name: "chardonnay",
        price: "5.50",
      },
      {
        name: "moscato",
        price: "5.50",
      },
      {
        name: "white zinfandel",
        price: "5.50",
      },
      {
        name: "plum wine",
        price: "5.50",
      },
    ],
  },
  {
    title: "wine by bottle",
    drinks: [
      {
        name: "j. lohr cabernet paso robles 2018",
        price: "40",
      },
      {
        name: "markham merlot napa valley 2018",
        price: "48",
      },
      {
        name: "sterlong vineyards merlot napa valley 2016",
        price: "55",
      },
      {
        name: "woodbridge by robert nodavi pinot grigio california",
        price: "22",
      },
      {
        name: "concannon chardonnay california",
        price: "26",
      },
    ],
  },
  {
    title: "sake",
    drinks: [
      { name: "hot sake small", price: "5.50" },
      { name: "hot sake large", price: "8.50" },
      { name: "cold sake small", price: "5.50" },
      { name: "cold sake large", price: "8.50" },
    ],
  },
  {
    title: "sake by bottle",
    drinks: [
      { name: "tozai snow maiden junmai nigori", price: "40" },
      { name: "momo kawa silver junmai craft sake", price: "40" },
    ],
  },
  {
    title: "domestic beer",
    drinks: [
      { name: "bud light", price: "4.50" },
      { name: "budweiser", price: "4.50" },
    ],
  },
  {
    title: "imported beer",
    drinks: [
      { name: "guiness draught beer", price: "6.50" },
      { name: "heineken", price: "5.00" },
      { name: "sapporo", price: "6.00" },
      { name: "tsing tso", price: "6.00" },
      { name: "corona", price: "5.00" },
    ],
  },
]

export default {
  alcohol,
  beverages,
}
