import styled from "styled-components/macro"

import CloseSvg from "src/assets/close.svg"
import {
  GOLD,
  NAV_BACKGROUND_COLOR,
  NAV_COLOR,
  HEADER_HEIGHT,
} from "../../constants"

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  right: 0;
  fill: ${GOLD};
  opacity: 0;

  &.visible {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &.hidden {
    opacity: 0;
    transition: opacity 0.5s;
  }
`

const Container = styled.div`
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${NAV_BACKGROUND_COLOR};
  color: ${NAV_COLOR};
  border-bottom: 1px solid rgb(214, 214, 214);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`

const NavTitle = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding: 0;
`

const SearchContainer = styled.div`
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  padding: 0 16px;
  border-radius: 20px;
  border: 1px solid ${GOLD};
`

const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;

  & path {
    fill: ${GOLD};
  }
`

const SearchInput = styled.input`
  width: calc(100% - 28px);
  border-style: none;
  background-color: transparent;
  color: ${GOLD};
  outline: none;
  padding: 0;
  font-size: 16px;
`

const SearchInputContainer = styled.div`
  width: 186px;
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
`

export {
  CloseIcon,
  Container,
  NavTitle,
  SearchContainer,
  SearchIconContainer,
  SearchInput,
  SearchInputContainer,
}
