import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { GOLD } from "../../constants"
import {
  Address,
  AddressContainer,
  AddressText,
  Container,
  DayAndTimeContainer,
  FreeDeliveryContainer,
  MapCanvas,
  MapContainer,
  PhoneTextContainer,
  SectionContainer,
  SectionInformationContainer,
  Text,
} from "./HomeStyledComponents"
import SectionHeading from "../SectionHeading"

const OPEN_HOURS = [
  {
    day: "Monday",
    openTime: "11:00am - 9:30pm",
  },
  {
    day: "Tuesday",
    openTime: "11:00am - 9:30pm",
  },
  {
    day: "Wednesday",
    openTime: "11:00am - 9:30pm",
  },
  {
    day: "Thursday",
    openTime: "11:00am - 9:30pm",
  },
  {
    day: "Friday",
    openTime: "11:00am - 10:00pm",
  },
  {
    day: "Saturday",
    openTime: "10:30am - 10:00pm",
  },
  {
    day: "Sunday",
    openTime: "10:30am - 9:30pm",
  },
]

const Home = () => {
  const {
    imageSharp: { fluid },
  } = useStaticQuery(
    graphql`
      {
        imageSharp(resize: { originalName: { eq: "golden-dragon.png" } }) {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `
  )

  return (
    <Container>
      {/* Heading Section */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Img
          fluid={fluid}
          style={{
            width: 300,
            padding: 0,
            margin: "16px 0",
            border: `4px solid ${GOLD}`,
          }}
        />
      </div>

      {/* Open Hours Section */}
      <SectionContainer>
        <SectionHeading>business hours</SectionHeading>
        <SectionInformationContainer>
          {OPEN_HOURS.map(({ day, openTime }) => (
            <DayAndTimeContainer key={day}>
              <Text>{day}</Text>
              <Text>{openTime}</Text>
            </DayAndTimeContainer>
          ))}
        </SectionInformationContainer>
      </SectionContainer>

      {/* Contact Information Section */}
      <SectionContainer>
        <SectionHeading>contact information</SectionHeading>
        <Address>
          <PhoneTextContainer>
            <Text>Phone:&nbsp;</Text>
            <Text>
              <a href="tel:+18014879888">(801) 487-9888</a>
            </Text>
          </PhoneTextContainer>
          <PhoneTextContainer>
            <Text>Fax:&nbsp;</Text>
            <Text>(801) 483-1222</Text>
          </PhoneTextContainer>
          <AddressContainer>
            <AddressText>1716 South State Street</AddressText>
            <AddressText>Salt Lake City, UT 84115</AddressText>
          </AddressContainer>
        </Address>
      </SectionContainer>

      {/* Free Delivery Section */}
      <SectionContainer>
        <SectionHeading>delivery</SectionHeading>
        <FreeDeliveryContainer>
          <Text>
            <a
              href="https://order.online/store/new-golden-dragon-157699"
              rel="noreferrer"
              target="_blank"
            >
              Order online here
            </a>
          </Text>
        </FreeDeliveryContainer>
      </SectionContainer>

      <br />

      {/* Google Maps Section*/}
      <MapContainer>
        <MapCanvas>
          <iframe
            width="100%"
            height="500"
            title="google_maps"
            id="gmap_canvas"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.317242816785!2d-111.89086928419388!3d40.73304437932956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8752f53265ce8835%3A0x38e04d72b92c7d1d!2sNew%20Golden%20Dragon!5e0!3m2!1sen!2sus!4v1655690016610!5m2!1sen!2sus"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          ></iframe>
        </MapCanvas>
      </MapContainer>
    </Container>
  )
}

export default Home
