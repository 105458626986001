import React from "react"
import { drinks } from "../../constants/data"

import SectionHeading from "../SectionHeading"

import {
  BeveragesHeadingContainer,
  Container,
  SectionContainer,
  Text,
} from "./DrinkMenuStyledComponents"

const DrinkMenu = () => {
  const { beverages, alcohol } = drinks

  return (
    <Container>
      {/* NON ALCOHOLIC DRINK */}
      <SectionContainer>
        <SectionHeading>{beverages.title}</SectionHeading>
        {beverages.items.map(item => (
          <div key={item.title}>
            <BeveragesHeadingContainer>
              <Text>{item.title}</Text>
              <Text>{item.price}</Text>
            </BeveragesHeadingContainer>
            <Text
              style={{
                fontSize: 14,
                marginLeft: 32,
                textTransform: "capitalize",
              }}
            >
              {item.drinks.join(", ")}
            </Text>
          </div>
        ))}
      </SectionContainer>
      {/* ALCOHOLIC DRINKS */}
      <SectionContainer>
        {alcohol.map(item => (
          <div key={item.title}>
            <SectionHeading>{item.title}</SectionHeading>
            {item.drinks.map(drink => (
              <BeveragesHeadingContainer key={drink.name}>
                <Text>{drink.name}</Text>
                <Text>{drink.price}</Text>
              </BeveragesHeadingContainer>
            ))}
          </div>
        ))}
      </SectionContainer>
    </Container>
  )
}

export default DrinkMenu
