import styled, { css } from "styled-components/macro"

import { GOLD, NAV_COLOR, HEADER_HEIGHT } from "src/constants"

const Container = styled.div``

const MenuDescription = styled.p`
  padding: 0;
  margin: 0 0 0 4px;
  font-size: 12px;
  white-space: pre;
  color: gray;
`

const MenuInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100% - 116px);
`

const MenuItem = styled.li`
  display: flex;
  justify-content: space-between;
  min-height: 100px;
  border-bottom: solid 2px ${GOLD};
  padding-bottom: 10px;

  :last-child {
    border-bottom: none;
  }
`

const MenuItemContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: ${HEADER_HEIGHT}px;
`

const MenuText = styled.p`
  padding: 0;
  margin: 0 0 0 4px;
  text-transform: capitalize;
  font-size: 18px;
  white-space: pre;
  word-break: break-word;
  white-space: normal;
  font-weight: ${({ bold }) => (bold ? "600" : "400")};
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TitleText = styled.p`
  color: gray;
`

const ToggleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: ${HEADER_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
`

const ToggleItem = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  border: 1px solid ${NAV_COLOR};
  background-color: ${({ active }) => (active ? NAV_COLOR : "white")};
`
const ToggleText = styled.span`
  ${({ active }) =>
    active &&
    css`
      color: white;
    `}
`

export {
  Container,
  MenuDescription,
  MenuInfoContainer,
  MenuItem,
  MenuItemContainer,
  MenuText,
  TitleContainer,
  TitleText,
  ToggleContainer,
  ToggleItem,
  ToggleText,
}
