import React, { useEffect, useMemo, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import {
  CategoryComboText,
  CategoryContainer,
  CategoryDescripionContainer,
  CategoryDescripionText,
  CategorySection,
  Container,
  MenuItemChineseTitleText,
  MenuItemContainer,
  MenuItemIdText,
  MenuItemPriceText,
  MenuItemTextContainer,
  MenuItemTitleText,
  SectionHeadingContainer,
} from "./MainMenuStyledComponents"
import SectionHeading from "../SectionHeading"
import { categories, options, menuItems } from "../../constants/data"
import { SearchContext } from "../../providers/SearchProvider"

const getMainMenu = searchTerm => {
  const groupByCategory = Object.keys(menuItems).reduce(
    (acc, menuItemNumber) => {
      const menuItem = { ...menuItems[menuItemNumber], id: menuItemNumber }
      const category = categories[menuItems[menuItemNumber].categoryId].title
      const shouldAddMenu = menuItems[menuItemNumber].title.includes(
        searchTerm.toLowerCase()
      )

      if (shouldAddMenu && !acc[category]) {
        acc[category] = [menuItem]
      } else if (shouldAddMenu && acc[category]) {
        acc[category] = [...acc[category], menuItem]
      }

      return acc
    },
    {}
  )

  return groupByCategory
}

const SpicyImage = props => {
  const { fixed, hidden } = props
  const style = hidden ? { visibility: "hidden" } : {}

  return <Img fixed={fixed} style={style} />
}

const CategoryMenuItems = ({ categoryMenuItems, fixed }) =>
  categoryMenuItems.map(menuItem => {
    const { chineseTitle, id, title, price, isSpicy, quantity } = menuItem
    const { small, large, normal } = price
    const extraText = quantity ? ` (${quantity})` : ""
    const priceText = normal ? normal : `(S) ${small} (L) ${large}`

    return (
      <MenuItemContainer key={title}>
        <MenuItemTextContainer>
          <SpicyImage fixed={fixed} hidden={!isSpicy} />
          <MenuItemIdText>{`${id}.`}</MenuItemIdText>
          <MenuItemTitleText>
            <MenuItemChineseTitleText>{chineseTitle}</MenuItemChineseTitleText>
            {title + extraText}
          </MenuItemTitleText>
        </MenuItemTextContainer>
        <MenuItemPriceText>{priceText}</MenuItemPriceText>
      </MenuItemContainer>
    )
  })

const Categories = ({ categoriesWithItems }) => {
  const {
    imageSharp: { fixed },
  } = useStaticQuery(
    graphql`
      {
        imageSharp(resize: { originalName: { eq: "spicy.png" } }) {
          fixed(width: 18, height: 18) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    `
  )

  return Object.keys(categoriesWithItems).map(categoryKey => {
    const categoryMenuItems = categoriesWithItems[categoryKey]

    if (categoryMenuItems === []) return null

    const categoryId = categoryMenuItems[0].categoryId
    const category = categories[categoryId]
    const optionId = category.optionId
    const option = options[optionId]
    const hasCombo = category.hasCombo

    return (
      <CategoryContainer key={category.title}>
        <CategorySection chefSpecial={categoryId === "14"}>
          <SectionHeadingContainer>
            <SectionHeading>
              {`${category.title} `}
              {option && <span>{`(${option})`}</span>}
            </SectionHeading>
          </SectionHeadingContainer>
          {category.description && (
            <CategoryDescripionContainer>
              <CategoryDescripionText>
                {category.description}
              </CategoryDescripionText>
            </CategoryDescripionContainer>
          )}
          {hasCombo && (
            <CategoryDescripionContainer>
              <CategoryComboText>
                Add $1.50 to make it a combo, served with ham fried rice, egg
                roll, fried shrimp and egg drop soup
              </CategoryComboText>
            </CategoryDescripionContainer>
          )}

          <CategoryMenuItems
            categoryMenuItems={categoryMenuItems}
            fixed={fixed}
          />
        </CategorySection>
      </CategoryContainer>
    )
  })
}

const MainMenu = () => {
  const [searchTerm, setSearchTerm] = useContext(SearchContext)

  useEffect(() => {
    return () => {
      setSearchTerm("")
    }
  }, [setSearchTerm])

  const categoriesWithItems = useMemo(() => getMainMenu(searchTerm), [
    searchTerm,
  ])

  return (
    <Container>
      <Categories categoriesWithItems={categoriesWithItems} />
    </Container>
  )
}

export default MainMenu
