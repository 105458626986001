export default {
  items: [
    {
      name: "baked salty egg bun",
      image: "baked-salty-egg-bun.jpg",
      price: "5.75",
    },
    {
      name: "bbq pork bun",
      image: "bbq-pork-bun.jpg",
      price: "5.75",
    },
    {
      name: "bbq pork crisp",
      image: "bbq-pork-crisp.jpg",
      price: "5.75",
    },
    {
      name: "beef ball",
      image: "beef-ball.jpg",
      price: "8.95",
    },
    {
      name: "beef rice noodles",
      image: "beef-rice-noodles.jpg",
      price: "5.75",
    },
    {
      name: "beef short ribs",
      image: "beef-short-ribs.jpg",
      price: "8.95",
    },
    {
      name: "boneless chicken feet",
      image: "boneless-chicken-feet.jpg",
      price: "8.95",
    },
    {
      name: "brown sugar tart",
      image: "brown-sugar-tart.jpg",
      price: "5.75",
    },
    {
      name: "chinese brocoli",
      image: "chinese-brocoli.jpg",
      price: "8.95",
    },

    {
      name: "clam in black bean sauce",
      image: "clam-in-black-bean-sauce.jpg",
      price: "8.95",
    },
    {
      name: "coconut bun",
      image: "coconut-bun.jpg",
      price: "5.75",
    },
    {
      name: "coconut cream bun",
      image: "coconut-cream-bun.jpg",
      price: "5.75",
    },
    {
      name: "cold dressing stomach",
      image: "cold-dressing-stomach.jpg",
      price: "8.95",
    },
    {
      name: "egg tart",
      image: "egg-tart.jpg",
      price: "5.75",
    },
    {
      name: "jelly fish",
      image: "jelly-fish.jpg",
      price: "8.95",
    },
    {
      name: "mixed puff with pork",
      image: "mixed-puff-with-pork.jpg",
      price: "5.75",
    },
    {
      name: "pineapple bun",
      image: "pineapple-bun.jpg",
      price: "5.75",
    },
    {
      name: "rice porridge",
      image: "rice-porridge.jpg",
      price: "5.75",
    },
    {
      name: "salt baked shrimp",
      image: "salt-baked-shrimp.jpg",
      price: "8.95",
    },
    {
      name: "salt baked squid",
      image: "salt-baked-squid.jpg",
      price: "8.95",
    },
    {
      name: "seaweed salad",
      image: "seaweed-salad.jpg",
      price: "8.95",
    },
    {
      name: "sesame ball",
      image: "sesame-ball.jpg",
      price: "5.75",
    },
    {
      name: "smelt fish",
      image: "smelt-fish.jpg",
      price: "8.95",
    },
    {
      name: "soft shell crab",
      image: "soft-shell-crab.jpg",
      price: "8.95",
    },
    {
      name: "spice beef tender",
      image: "spice-beef-tender.jpg",
      price: "8.95",
    },
    {
      name: "swirl coconut bun",
      image: "swirl-coconut-bun.jpg",
      price: "5.75",
    },
    {
      name: "white bbq pork bun",
      image: "white-bbq-pork-bun.jpg",
      price: "5.75",
    },
    {
      name: "x.o noodles",
      image: "x-o-noodles.jpg",
      price: "5.75",
    },
  ],
}
