import React from "react"

import {
  Container,
  NavItem,
  NavItemContainer,
  NavLink,
} from "./BottomNavStyledComponents"
import { NAV_COLOR, NAV_LINKS } from "../../constants"

const NavLinks = props => {
  const { activeNavLink } = props

  return NAV_LINKS.map(link => {
    const { Icon, name, to } = link
    const active = name === activeNavLink

    return (
      <NavItem key={name} active={active}>
        <NavLink to={to} active={active.toString()}>
          <Icon style={{ fill: NAV_COLOR, marginBottom: 4 }} />
          {name}
        </NavLink>
      </NavItem>
    )
  })
}

const BottomNav = props => {
  const { activeNavLink } = props

  return (
    <Container>
      <NavItemContainer>
        <NavLinks activeNavLink={activeNavLink} />
      </NavItemContainer>
    </Container>
  )
}

export default BottomNav
