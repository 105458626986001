import React, { useContext, useEffect, useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import {
  Container,
  MenuDescription,
  MenuInfoContainer,
  MenuItem,
  MenuItemContainer,
  MenuText,
  TitleContainer,
  TitleText,
  ToggleContainer,
  ToggleItem,
  ToggleText,
} from "./DimSumMenuStyledComponents"
import { dimSumMenuItems, weekendDimSumMenuItem } from "../../constants/data"
import { SearchContext } from "../../providers/SearchProvider"

const Image = ({ image }) => {
  const {
    allFile: { nodes },
  } = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "dim-sum" } }) {
          nodes {
            childImageSharp {
              fixed {
                originalName
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    `
  )

  const images = useMemo(() => {
    return nodes.reduce((acc, { childImageSharp: { fixed } }) => {
      return {
        ...acc,
        [fixed.originalName]: fixed,
      }
    }, {})
  }, [nodes])

  if (images[image]) {
    return (
      <Img
        fixed={images[image]}
        style={{ width: 100, height: 100, borderRadius: 20, margin: "0 8px" }}
      />
    )
  }

  return <div />
}

const isWeekend = () => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay#using_getday
  const day = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
    new Date()
  )

  return ["Saturday", "Sunday"].includes(day)
}

const DimSumMenu = () => {
  // context
  const [searchTerm, setSearchTerm] = useContext(SearchContext)
  // state
  const [displayWeekendMenu, setWeekendMenu] = useState(isWeekend())
  const { items: allDayItems } = dimSumMenuItems
  const { items: weekendItems } = weekendDimSumMenuItem

  // useEffects
  useEffect(() => {
    return () => {
      setSearchTerm("")
    }
  }, [setSearchTerm])

  const items = useMemo(() => {
    const menuItems = displayWeekendMenu
      ? [...weekendItems, ...allDayItems]
      : allDayItems

    if (!searchTerm) return menuItems
    else
      return menuItems.filter(({ name }) =>
        name.toLowerCase().includes(searchTerm.toLowerCase())
      )
  }, [allDayItems, displayWeekendMenu, searchTerm, weekendItems])

  return (
    <Container>
      <ToggleContainer>
        <ToggleItem
          onClick={() => setWeekendMenu(false)}
          active={!displayWeekendMenu}
        >
          <ToggleText active={!displayWeekendMenu}>All Day</ToggleText>
        </ToggleItem>
        <ToggleItem
          onClick={() => setWeekendMenu(true)}
          active={displayWeekendMenu}
        >
          <ToggleText active={displayWeekendMenu}>Weekend</ToggleText>
        </ToggleItem>
      </ToggleContainer>
      {/* Menu Items */}
      <MenuItemContainer>
        {displayWeekendMenu && (
          <TitleContainer>
            <TitleText>only served from 10:30 AM til 2:30PM</TitleText>
          </TitleContainer>
        )}
        {items?.map(({ description, name, image, price }) => {
          return (
            <MenuItem key={name}>
              <MenuInfoContainer>
                <div>
                  <MenuText bold>{name}</MenuText>
                  <MenuDescription>{description}</MenuDescription>
                </div>
                <MenuText>${price}</MenuText>
              </MenuInfoContainer>
              <Image image={image} />
            </MenuItem>
          )
        })}
      </MenuItemContainer>
    </Container>
  )
}

export default DimSumMenu
