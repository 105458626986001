import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { GOLD } from "../../constants"
import {
  AffordableFamilyDinnerPriceContainer,
  AffordableFamilyDinnerPricesContainer,
  AffordableFamilyItemContainer,
  AffordableFamilyItemsContainer,
  ChineseText,
  ChineseItemText,
  Container,
  FamilyDinnerDescriptionContainer,
  FamilyDinnerMenuItemContainer,
  FamilyDinnerMenuItemsContainer,
  // FriedRicesContainer,
  // FriedRiceItem,
  LunchMenuItem,
  LunchMenuItemsContainer,
  LunchMenuTitleContainer,
  SectionContainer,
  Text,
} from "./SpecialMenuStyledComponents"
import SectionHeading from "../SectionHeading"
import { specialCategories } from "../../constants/data"

const SpicyImage = props => {
  const { fixed, hidden = false } = props
  const style = hidden ? { visibility: "hidden" } : {}

  return <Img fixed={fixed} style={style} />
}

const SpecialMenu = () => {
  const {
    imageSharp: { fixed },
  } = useStaticQuery(
    graphql`
      {
        imageSharp(resize: { originalName: { eq: "spicy.png" } }) {
          fixed(width: 18, height: 18) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    `
  )

  const { affordableFamilyDinner, familyDinner, lunchMenu } = specialCategories

  return (
    <Container>
      {/* Family Dinner Section */}
      <SectionContainer>
        <SectionHeading>{familyDinner.title}</SectionHeading>
        <FamilyDinnerDescriptionContainer>
          <Text style={{ fontSize: 32, fontWeight: 600 }}>
            {familyDinner.priceDescription}
          </Text>
          <Text style={{ fontSize: 16 }}>
            {familyDinner.serviceDescription}
          </Text>
          <Text style={{ marginTop: 8 }}>{familyDinner.description}</Text>
        </FamilyDinnerDescriptionContainer>
        <FamilyDinnerMenuItemsContainer>
          {familyDinner.items.map(item => (
            <FamilyDinnerMenuItemContainer key={item.title}>
              <Text style={{ textAlign: "center" }}>{item.title}</Text>
              {item.extra && (
                <Text style={{ color: "#941010" }}>
                  &nbsp;${item.extra} extra
                </Text>
              )}
              <SpicyImage fixed={fixed} hidden={!item.isSpicy} />
            </FamilyDinnerMenuItemContainer>
          ))}
        </FamilyDinnerMenuItemsContainer>
      </SectionContainer>

      {/* Affordable Family Dinner */}
      <SectionContainer>
        <SectionHeading>{affordableFamilyDinner.title}</SectionHeading>
        <AffordableFamilyDinnerPricesContainer>
          {affordableFamilyDinner.prices.map(price => (
            <AffordableFamilyDinnerPriceContainer key={price.title}>
              <Text>
                <ChineseText>{price.chineseTitle}</ChineseText>
                {price.title}
              </Text>
              <Text>{price.amount}</Text>
            </AffordableFamilyDinnerPriceContainer>
          ))}
        </AffordableFamilyDinnerPricesContainer>
        <AffordableFamilyItemsContainer>
          {affordableFamilyDinner.items.map(item => (
            <AffordableFamilyItemContainer key={item.title}>
              <Text style={{ fontSize: 16, textAlign: "center" }}>
                <ChineseItemText>{item.chineseTitle}</ChineseItemText>
                {item.title}
              </Text>
              <SpicyImage fixed={fixed} hidden={!item.isSpicy} />
            </AffordableFamilyItemContainer>
          ))}
        </AffordableFamilyItemsContainer>
      </SectionContainer>

      {/* Lunch Menu Section */}
      <SectionContainer>
        <SectionHeading>{lunchMenu.title}</SectionHeading>
        <Text
          style={{
            color: GOLD,
            fontSize: 16,
            textAlign: "center",
            margin: "16px 32px 8px 32px",
          }}
        >
          {lunchMenu.description}
        </Text>
        {lunchMenu.combonations.map(combo => (
          <div key={combo.title}>
            <LunchMenuTitleContainer>
              <Text style={{ fontWeight: 600 }}>{combo.title}</Text>
              <Text>{combo.price}</Text>
            </LunchMenuTitleContainer>
            <Text
              style={{
                color: GOLD,
                fontSize: 14,
                textAlign: "center",
                marginTop: 8,
              }}
            >
              {combo.description}
            </Text>
            <LunchMenuItemsContainer>
              {combo.items.map(item => (
                <LunchMenuItem key={item.id}>
                  <SpicyImage fixed={fixed} hidden={!item.isSpicy} />
                  <Text>{`${item.id}. ${item.title}`}</Text>
                </LunchMenuItem>
              ))}
            </LunchMenuItemsContainer>
          </div>
        ))}
      </SectionContainer>

      {/* Fried Rice Section */}
      {/* <SectionContainer>
        <SectionHeading>{friedRice.title}</SectionHeading>
        <FriedRicesContainer>
          {friedRice.items.map(item => (
            <FriedRiceItem key={item.title}>
              <Text>{item.title}</Text>
              <Text>{item.price}</Text>
            </FriedRiceItem>
          ))}
        </FriedRicesContainer>
      </SectionContainer> */}
    </Container>
  )
}

export default SpecialMenu
