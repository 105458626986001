import styled from "styled-components/macro"

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgb(120, 80, 39);
  color: white;
  padding: 12px 24px;
  width: 100%;
  border-radius: 18px;
`

const Text = styled.p`
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-align: center;

  & > span {
    text-transform: capitalize;
    display: inline-block;
  }
`

export { Container, Text }
