import React, { useContext } from "react"

import SearchIcon from "src/assets/search.svg"
import {
  CloseIcon,
  Container,
  NavTitle,
  SearchContainer,
  SearchIconContainer,
  SearchInput,
  SearchInputContainer,
} from "./HeaderStyledComponents"
import { MENU_PATH, DIM_SUM_MENU_PATH } from "./../../constants/index"
import { SearchContext } from "../../providers/SearchProvider"

const Header = () => {
  const [searchTerm, setSearchTerm] = useContext(SearchContext)

  const showSearch =
    typeof window !== "undefined" &&
    (window.location.pathname === MENU_PATH ||
      window.location.pathname === DIM_SUM_MENU_PATH)
  const hideCloseIcon = searchTerm === ""

  return (
    <header>
      <Container>
        {showSearch ? (
          <SearchContainer>
            <SearchIconContainer>
              <SearchIcon />
            </SearchIconContainer>
            <SearchInputContainer>
              <SearchInput
                aria-label="Search Menu Items"
                placeholder="Search Menu Items"
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value)
                  window.scrollTo(0, 0)
                }}
              />
              <CloseIcon
                className={hideCloseIcon ? "hidden" : "visible"}
                onClick={() => setSearchTerm("")}
              />
            </SearchInputContainer>
          </SearchContainer>
        ) : (
          <NavTitle>New Golden Dragon</NavTitle>
        )}
      </Container>
    </header>
  )
}

export default Header
