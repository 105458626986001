import styled from "styled-components/macro"

const BeveragesHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  padding: 0 16px;
  text-transform: capitalize;
`

const Container = styled.div``

const SectionContainer = styled.div`
  margin: 16px 12px;
  text-transform: capitalize;
`

const Text = styled.p`
  margin: 0;
  padding: 0;
`

export { BeveragesHeadingContainer, Container, SectionContainer, Text }
