export default {
  1: {
    title: "cream cheese wonton",
    chineseTitle: "芝 士 雲 吞",
    categoryId: "1",
    quantity: 6,
    price: {
      small: null,
      large: null,
      normal: "8.95",
    },
    isSpicy: false,
  },
  2: {
    title: "egg roll",
    chineseTitle: "春 捲",
    categoryId: "1",
    quantity: 2,
    price: {
      small: null,
      large: null,
      normal: "4",
    },
    isSpicy: false,
  },
  3: {
    title: "silver wrapped chicken",
    chineseTitle: "紙 包 雞",
    categoryId: "1",
    quantity: 6,
    price: {
      small: null,
      large: null,
      normal: "8.55",
    },
    isSpicy: false,
  },
  4: {
    title: "tempura shrimp",
    chineseTitle: "炸 蝦",
    categoryId: "1",
    quantity: 6,
    price: {
      small: null,
      large: null,
      normal: "10.95",
    },
    isSpicy: false,
  },
  5: {
    title: "shrimp balls",
    chineseTitle: "蝦 丸",
    categoryId: "1",
    quantity: 6,
    price: {
      small: null,
      large: null,
      normal: "10.95",
    },
    isSpicy: false,
  },
  6: {
    title: "pot stickers",
    chineseTitle: "鍋 貼",
    categoryId: "1",
    quantity: 6,
    price: {
      small: null,
      large: null,
      normal: "10.95",
    },
    isSpicy: false,
  },
  7: {
    title: "b.b.q. spare rib",
    chineseTitle: "燒 排 骨",
    categoryId: "1",
    quantity: 6,
    price: {
      small: null,
      large: null,
      normal: "10.95",
    },
    isSpicy: false,
  },
  8: {
    title: "minced chicken with lettuce",
    chineseTitle: "雞 肉 生 菜 包",
    categoryId: "1",
    quantity: 6,
    price: {
      small: null,
      large: null,
      normal: "10.95",
    },
    isSpicy: false,
  },
  9: {
    title: "bo bo platter",
    chineseTitle: "寶 寶 盤",
    categoryId: "1",
    quantity: "for 2 people",
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  "9a": {
    title: "b.b.q. pork",
    chineseTitle: "叉 燒",
    categoryId: "1",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "9.95",
    },
    isSpicy: false,
  },
  10: {
    title: "egg drop soup",
    chineseTitle: "蛋 花 湯",
    categoryId: "2",
    quantity: null,
    price: {
      small: "1.95",
      large: "7.95",
      normal: null,
    },
    isSpicy: false,
  },
  11: {
    title: "hot & sour soup",
    chineseTitle: "酸 辣 湯",
    categoryId: "2",
    quantity: null,
    price: {
      small: "1.95",
      large: "7.95",
      normal: null,
    },
    isSpicy: true,
  },
  12: {
    title: "wonton soup",
    chineseTitle: "雲 吞 湯",
    categoryId: "2",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "2.95",
    },
    isSpicy: false,
  },
  13: {
    title: "eight treasures tofu soup",
    chineseTitle: "八 寶 豆 腐 湯",
    categoryId: "2",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "9.95",
    },
    isSpicy: false,
  },
  14: {
    title: "house special wonton soup",
    chineseTitle: "本 樓 雲 吞 湯",
    categoryId: "2",
    quantity: "for 2",
    price: {
      small: null,
      large: null,
      normal: "10.95",
    },
    isSpicy: false,
  },
  15: {
    title: "crab meat & fish stomach soup",
    chineseTitle: "蟹 肉 魚 肚 羹",
    categoryId: "2",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "18.95",
    },
    isSpicy: false,
  },
  16: {
    title: "crab meat & fish fin soup",
    chineseTitle: "紅 燒 蟹 肉 魚 翅",
    categoryId: "2",
    quantity: "for 4",
    price: {
      small: null,
      large: null,
      normal: "39.95",
    },
    isSpicy: false,
  },
  17: {
    title: "beef with snow peas",
    chineseTitle: "雪 豆 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  18: {
    title: "beef with broccoli",
    chineseTitle: "西 蘭 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  19: {
    title: "mongolian beef",
    chineseTitle: "蒙 古 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: true,
  },
  20: {
    title: "pepper beef with tomato",
    chineseTitle: "番 茄 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  21: {
    title: "beef in oyster sauce",
    chineseTitle: "蠔 油 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  22: {
    title: "szechuan style beef",
    chineseTitle: "魚 香 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: true,
  },
  23: {
    title: "beef with mixed vegetables",
    chineseTitle: "雜 菜 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  24: {
    title: "hong kong style beef",
    chineseTitle: "中 式 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  25: {
    title: "beef with garlic sauce",
    chineseTitle: "乾 燒 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: true,
  },
  "25A": {
    title: "beef with green bean black bean sauce",
    chineseTitle: "豉 汁 四 季 豆 牛",
    categoryId: "3",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: true,
  },
  26: {
    title: "chicken with broccoli",
    chineseTitle: "西 蘭 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  27: {
    title: "kung po chicken",
    chineseTitle: "宮 保 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  28: {
    title: "sweet & sour chicken",
    chineseTitle: "甜 酸 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  29: {
    title: "sesame chicken",
    chineseTitle: "芝 麻 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  30: {
    title: "general tso's chicken",
    chineseTitle: "左 宗 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  31: {
    title: "curry chicken",
    chineseTitle: "咖 喱 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  32: {
    title: "chicken with cashew nuts",
    chineseTitle: "腰 果 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  33: {
    title: "moo goo gai pan",
    chineseTitle: "蘑 菇 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  34: {
    title: "chicken with straw mushrooms",
    chineseTitle: "鮮 菇 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  35: {
    title: "lemon chicken",
    chineseTitle: "檸 檬 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  36: {
    title: "shredded chicken szechuan style",
    chineseTitle: "魚 香 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  37: {
    title: "spicy chicken",
    chineseTitle: "川 椒 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  38: {
    title: "mu shu chicken",
    chineseTitle: "木 須 雞",
    categoryId: "4",
    quantity: "4 crepes",
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  39: {
    title: "chicken with mixed vegetables",
    chineseTitle: "雜 菜 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  40: {
    title: "chicken & green bean in black bean sauce",
    chineseTitle: "豉 汁 四 季 豆 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  41: {
    title: "pon pon chicken",
    chineseTitle: "棒 棒 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  42: {
    title: "orange chicken",
    chineseTitle: "橙 雞",
    categoryId: "4",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  43: {
    title: "shrimp & green bean in black bean sauce",
    chineseTitle: "豉 汁 四 季 豆 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  44: {
    title: "black pepper shrimp",
    chineseTitle: "黑 椒 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: true,
  },
  45: {
    title: "shrimp with broccoli",
    chineseTitle: "西 蘭 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  46: {
    title: "shrimp with snow peas",
    chineseTitle: "雪 豆 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  47: {
    title: "shrimp with garlic sauce",
    chineseTitle: "乾 燒 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: true,
  },
  48: {
    title: "szechuan style shrimp",
    chineseTitle: "魚 香 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: true,
  },
  49: {
    title: "shrimp with mixed vegetables",
    chineseTitle: "雜 菜 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  50: {
    title: "sweet & sour shrimp",
    chineseTitle: "甜 酸 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  51: {
    title: "shrimp with lobster sauce",
    chineseTitle: "蝦 龍 湖",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  52: {
    title: "kung po shrimp",
    chineseTitle: "宮 保 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: true,
  },
  53: {
    title: "shrimp with cashew nuts",
    chineseTitle: "腰 果 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  54: {
    title: "walnut shrimp with salad dressing",
    chineseTitle: "核 桃 蝦",
    categoryId: "5",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "18.95",
    },
    isSpicy: false,
  },
  55: {
    title: "happy family",
    chineseTitle: "合 家 歡",
    categoryId: "5",
    quantity: "chicken, shrimp, beef & roast pork",
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  56: {
    title: "sweet & sour pork",
    chineseTitle: "甜 酸 肉",
    categoryId: "6",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  57: {
    title: "roast pork with snow peas",
    chineseTitle: "雪 豆 叉 燒",
    categoryId: "6",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  58: {
    title: "mu shu pork",
    chineseTitle: "木 須 肉",
    categoryId: "6",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  59: {
    title: "shredded pork szechuan style",
    chineseTitle: "魚 香 肉",
    categoryId: "6",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  60: {
    title: "twice cooked pork",
    chineseTitle: "回 鍋 肉",
    categoryId: "6",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  61: {
    title: "roast pork with mixed vegetables",
    chineseTitle: "雜 菜 叉 燒",
    categoryId: "6",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  62: {
    title: "pork with garlic sauce",
    chineseTitle: "乾 燒 肉 絲",
    categoryId: "6",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  63: {
    title: "pon pon pork",
    chineseTitle: "棒 棒 肉",
    categoryId: "6",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  64: {
    title: "vegetable delight",
    chineseTitle: "素 什 錦",
    categoryId: "7",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  65: {
    title: "buddha supreme with tofu",
    chineseTitle: "羅 漢 齋 豆 腐",
    categoryId: "7",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  66: {
    title: "braised tofu and assorted vegetable",
    chineseTitle: "紅 燒 豆 腐",
    categoryId: "7",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  67: {
    title: "chinese broccoli in oyster sauce",
    chineseTitle: "蠔 油 芥 蘭",
    categoryId: "7",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  68: {
    title: "two kinds of mushrooms and vegetables",
    chineseTitle: "時 菜 雙 菇",
    categoryId: "7",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  69: {
    title: "chinese broccoli, tofu & black mushrooms",
    chineseTitle: "芥 蘭 冬 菇 豆 腐",
    categoryId: "7",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  70: {
    title: "seasonal fresh chinese vegetable",
    chineseTitle: "時 菜",
    categoryId: "7",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  71: {
    title: "abalone mushroom with vegetable",
    chineseTitle: "鮑 魚 菇 扒 時 菜",
    categoryId: "7",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  72: {
    title: "gluten with seasonal vegetable",
    chineseTitle: "時 菜 麵 筋",
    categoryId: "7",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  73: {
    title: "chicken egg foo yung",
    chineseTitle: "雞 芙 蓉",
    categoryId: "8",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  74: {
    title: "ham egg foo yung",
    chineseTitle: "火 腿 芙 蓉",
    categoryId: "8",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  75: {
    title: "roast pork egg foo yung",
    chineseTitle: "叉 燒 芙 蓉",
    categoryId: "8",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  76: {
    title: "beef egg foo yung",
    chineseTitle: "牛 芙 蓉",
    categoryId: "8",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  77: {
    title: "shrimp egg foo yung",
    chineseTitle: "蝦 芙 蓉",
    categoryId: "8",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  78: {
    title: "vegetable egg foo yung",
    chineseTitle: "蛋 芙 蓉",
    categoryId: "8",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  79: {
    title: "chicken lo mein",
    chineseTitle: "雞 撈 面",
    categoryId: "9",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  80: {
    title: "beef lo mein",
    chineseTitle: "牛 撈 面",
    categoryId: "9",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  81: {
    title: "pork lo mein",
    chineseTitle: "肉 絲 撈 面",
    categoryId: "9",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  82: {
    title: "shrimp lo mein",
    chineseTitle: "蝦 撈 面",
    categoryId: "9",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  83: {
    title: "vegetable lo mein",
    chineseTitle: "素 菜 撈 面",
    categoryId: "9",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  84: {
    title: "house special lo mein",
    chineseTitle: "八 珍 撈 面",
    categoryId: "9",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  85: {
    title: "seafood lo mein",
    chineseTitle: "海 鮮 撈 面",
    categoryId: "9",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  86: {
    title: "chicken chop suey",
    chineseTitle: " 雞 面 乾",
    categoryId: "10",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  87: {
    title: "beef chop suey",
    chineseTitle: "牛 面 乾",
    categoryId: "10",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  88: {
    title: "roast pork chop suey",
    chineseTitle: "叉 燒 面 乾",
    categoryId: "10",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  89: {
    title: "shrimp chop suey",
    chineseTitle: "蝦 面 乾",
    categoryId: "10",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  90: {
    title: "vegetable chop suey",
    chineseTitle: "素 菜 面 乾",
    categoryId: "10",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  91: {
    title: "house special chop suey",
    chineseTitle: "八 珍 面 乾",
    categoryId: "10",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  92: {
    title: "ham fried rice",
    chineseTitle: "火 腿 炒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  93: {
    title: "chicken fried rice",
    chineseTitle: "雞 炒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  94: {
    title: "beef fried rice",
    chineseTitle: "牛 炒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  95: {
    title: "roast pork fried rice",
    chineseTitle: "叉 燒 炒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  96: {
    title: "shrimp fried rice",
    chineseTitle: "蝦 炒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  97: {
    title: "house special fried rice",
    chineseTitle: "本 樓 炒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  98: {
    title: "vegetable fried rice",
    chineseTitle: "素 菜 炒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  99: {
    title: "seafood fried rice",
    chineseTitle: "海 鮮 炒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  100: {
    title: "dried scallop & egg white fried rice",
    chineseTitle: "瑤 柱 蛋 白 炒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  101: {
    title: "salty fish and chicken fried rice",
    chineseTitle: "咸 魚 雞 粒 飯",
    categoryId: "11",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  102: {
    title: "chicken pan fried noodles",
    chineseTitle: "雞 炒 麵",
    categoryId: "12",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  103: {
    title: "beef pan fried noodles",
    chineseTitle: "牛 炒 麵",
    categoryId: "12",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  104: {
    title: "shredded pork pan fried noodles",
    chineseTitle: "肉 絲 炒 麵",
    categoryId: "12",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  105: {
    title: "shrimp pan fried noodles",
    chineseTitle: "蝦 炒 麵",
    categoryId: "12",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  106: {
    title: "seafood pan fried noodles",
    chineseTitle: "海 鮮 炒 麵",
    categoryId: "12",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  107: {
    title: "house special pan fried noodles",
    chineseTitle: "八 珍 炒 麵",
    categoryId: "12",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  108: {
    title: "vegetable pan fried noodles",
    chineseTitle: "素 菜 炒 麵",
    categoryId: "12",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  109: {
    title: "singapore rice noodles",
    chineseTitle: "星 洲 米 粉",
    categoryId: "12",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: true,
  },
  110: {
    title: "beef with flat noodles",
    chineseTitle: "乾 炒（濕 炒）牛 河",
    categoryId: "12",
    quantity: "dry or wet",
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  111: {
    title: "house special flat noodles",
    chineseTitle: "乾 炒（濕 炒）八 珍 河",
    categoryId: "12",
    quantity: "dry or wet",
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  112: {
    title: "seafood flat noodles",
    chineseTitle: "乾 炒 (濕 炒)海 鮮 河",
    categoryId: "12",
    quantity: "dry or wet",
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  113: {
    title: "beef flat noodles wet",
    chineseTitle: "豉 汁 濕 炒 牛 河",
    categoryId: "12",
    quantity: "black bean sauce",
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  114: {
    title: "wonton in supreme soup",
    chineseTitle: "鮮 蝦 雲 吞",
    categoryId: "13",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  115: {
    title: "wontons with egg noodles soup",
    chineseTitle: "雲 吞 湯 麵（粉)",
    categoryId: "13",
    quantity: "flat",
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  116: {
    title: "beef stew with egg noodles soup",
    chineseTitle: "牛 腩 湯 麵 (粉)",
    categoryId: "13",
    quantity: "flat",
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  117: {
    title: "wontons, beef stew egg (flat) noodles soup",
    chineseTitle: "雲 吞 牛 腩 湯 麵（粉)",
    categoryId: "13",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  118: {
    title: "seafood egg (flat) noodles soup",
    chineseTitle: "海 鮮 湯 麵（粉)",
    categoryId: "13",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  119: {
    title: "house special egg (flat) noodles soup",
    chineseTitle: "八 珍 湯 麵（粉)",
    categoryId: "13",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "12.95",
    },
    isSpicy: false,
  },
  120: {
    title: "chicken egg (flat) noodles soup",
    chineseTitle: "雞 湯 麵（粉)",
    categoryId: "13",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  121: {
    title: "vegetable egg (flat) noodles soup",
    chineseTitle: "素 菜 湯 麵（粉)",
    categoryId: "13",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "11.95",
    },
    isSpicy: false,
  },
  122: {
    title: "peking duck",
    chineseTitle: "北 京 鴨（全 只)",
    categoryId: "14",
    quantity: "whole",
    price: {
      small: null,
      large: null,
      normal: "Market Price",
    },
    isSpicy: false,
  },
  123: {
    title: "b.b.q roast duck",
    chineseTitle: "烤 鴨 （半 只)",
    categoryId: "14",
    quantity: "half",
    price: {
      small: null,
      large: null,
      normal: "19.95",
    },
    isSpicy: false,
  },
  124: {
    title: "shanghai chicken",
    chineseTitle: "上 海 雞（半 只)",
    categoryId: "14",
    quantity: "half",
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  125: {
    title: "queen yang chicken",
    chineseTitle: "貴 妃 雞（半 只)",
    categoryId: "14",
    quantity: "half",
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  126: {
    title: "beef with tofu",
    chineseTitle: "牛 肉 豆 腐",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  127: {
    title: "deep fried tofu",
    chineseTitle: "琵 琶 豆 腐",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  128: {
    title: "mapo tofu",
    chineseTitle: "麻 婆 豆 腐",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  129: {
    title: "steamed tofu with seafood",
    chineseTitle: "海 鮮 蒸 豆 腐",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "17.95",
    },
    isSpicy: false,
  },
  130: {
    title: "chinese broccoli with beef",
    chineseTitle: "中 芥 蘭 牛 肉",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  131: {
    title: "chinese broccoli with seafood",
    chineseTitle: "中 芥 蘭 三 鮮",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "17.95",
    },
    isSpicy: false,
  },
  132: {
    title: "chinese broccoli with shrimp cakes",
    chineseTitle: "中 芥 蘭 蝦 餅",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "17.95",
    },
    isSpicy: false,
  },
  133: {
    title: "pork with preserved vegetable",
    chineseTitle: "榨 菜 肉 絲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  134: {
    title: "ground pork with green bean",
    chineseTitle: "干 扁 四 季 豆",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  135: {
    title: "szechuan style boilded fish/beef",
    chineseTitle: "水 煮 魚 / 牛",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  136: {
    title: "black pepper pork chop on hot plate",
    chineseTitle: "鐵 板 黑 椒 豬 排",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  137: {
    title: "peking pork chop",
    chineseTitle: "京 都 肉 排",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  138: {
    title: "salt baked pork chop",
    chineseTitle: "椒 盐 肉 排",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: true,
  },
  139: {
    title: "salt baked squid",
    chineseTitle: "椒 盐 鱿 鱼",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: true,
  },
  140: {
    title: "salt baked shrimp",
    chineseTitle: "椒 盐 大 蝦",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: true,
  },
  141: {
    title: "deep fried intestine",
    chineseTitle: "炸 大 腸",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  142: {
    title: "intestine with preserved cabbage",
    chineseTitle: "酸 菜 大 腸",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  143: {
    title: "preserved cabbage with fish fillets",
    chineseTitle: "酸 菜 魚 片",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  144: {
    title: "preserved cabbage with squid",
    chineseTitle: "酸 菜 鱿 鱼",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  145: {
    title: "chinese fresh vegetable with squid",
    chineseTitle: "時 菜 鱿 鱼",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  146: {
    title: "squid with black bean sauce",
    chineseTitle: "豉 汁 鱿 鱼",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  147: {
    title: "fish and fresh vegetable",
    chineseTitle: "時 菜 班 球",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  148: {
    title: "sauteed seafood",
    chineseTitle: "油 爆 三 鮮",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  149: {
    title: "clam in black bean sauce",
    chineseTitle: "豉 汁 炒 蜆",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "Market Price",
    },
    isSpicy: false,
  },
  150: {
    title: "assorted vegetable and meat pot",
    chineseTitle: "什 燴 豆 腐 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  151: {
    title: "buddha vegetable and tofu pot",
    chineseTitle: "羅 漢 豆 腐 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "13.95",
    },
    isSpicy: false,
  },
  152: {
    title: "duck feet with black mushrooms pot",
    chineseTitle: "鴨 掌 冬 菇 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  153: {
    title: "shrimp balls and black mushrooms pot",
    chineseTitle: "蝦 丸 冬 菇 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  154: {
    title: "shrimp balls and tofu pot",
    chineseTitle: "蝦 丸 豆 腐 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  155: {
    title: "shrimp balls and sea cucumbers pot",
    chineseTitle: "蝦 丸 海 參 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "24.95",
    },
    isSpicy: false,
  },
  156: {
    title: "beef stew in hot pot",
    chineseTitle: "牛 腩 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  157: {
    title: "preserved vegetable with chinese bacon pot",
    chineseTitle: "梅 菜 扣 肉 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  158: {
    title: "assorted seafood & vermicelli noodles pot",
    chineseTitle: "海 皇 粉 絲 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "17.95",
    },
    isSpicy: false,
  },
  159: {
    title: "eggplant and shredded pork hot pot",
    chineseTitle: "魚 香 茄 子 煲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  160: {
    title: "eggplant stuff with shrimp",
    chineseTitle: "釀 茄 子",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  161: {
    title: "assorted vegetable, meat and seafood",
    chineseTitle: "八 珍 扒 豆 腐",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  162: {
    title: "steamed sea bass",
    chineseTitle: "清 蒸 銀 雪 魚",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "39.95",
    },
    isSpicy: false,
  },
  163: {
    title: "shredded pork with dried bean curd",
    chineseTitle: "五 香 豆 干 肉 絲",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "14.95",
    },
    isSpicy: false,
  },
  164: {
    title: "ginger & onion or salt baked crab",
    chineseTitle: "姜 蔥 或 椒 盐 或 香 港 蟹",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "Seasonal Price",
    },
    isSpicy: false,
  },
  165: {
    title: "beef tripe with black bean sauce",
    chineseTitle: "豉 汁 牛 柏 葉",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "15.95",
    },
    isSpicy: false,
  },
  166: {
    title: "frog legs with x.o. sauce",
    chineseTitle: "X O 田 雞",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  167: {
    title: "frog legs with black bean sauce",
    chineseTitle: "豉 汁 田 雞",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  168: {
    title: "fish fillet with x.o. sauce",
    chineseTitle: "X O 魚 片",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  169: {
    title: "supreme tofu",
    chineseTitle: "上 品 豆 腐",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "16.95",
    },
    isSpicy: false,
  },
  170: {
    title: "whole fish deep fried with sour and spicy sauce",
    chineseTitle: "酸 辣 鱸 魚",
    categoryId: "14",
    quantity: null,
    price: {
      small: null,
      large: null,
      normal: "Seasonal Price",
    },
    isSpicy: true,
  },
}
