import styled from "styled-components/macro"

import { BOTTOM_NAV_HEIGHT, HEADER_HEIGHT } from "../../constants"

const ContentContainer = styled.main`
  margin-top: ${HEADER_HEIGHT}px;
  margin-bottom: ${BOTTOM_NAV_HEIGHT}px;
  overflow: auto;
`

export { ContentContainer }
