import React from "react"
import Helmet from "react-helmet"

const SEO = props => {
  const { title } = props

  return <Helmet htmlAttributes={{ lang: "en" }} title={title} />
}

export default SEO
