import MenuIcon from "src/assets/menu.svg"
import HomeIcon from "src/assets/home.svg"
import StarIcon from "src/assets/star.svg"
import DumplingIcon from "src/assets/dumpling.svg"
import DrinkIcon from "src/assets/drink.svg"

// Style Constants
export const BOTTOM_NAV_HEIGHT = 56
export const HEADER_HEIGHT = 56
export const NAV_BACKGROUND_COLOR = "rgb(247,243,237)"
export const NAV_COLOR = "rgb(197,179,88)"
export const GOLD = "rgb(197,179,88)"
export const DARK_RED = "rgb(232, 85, 0)"

// Nav Link Names
export const HOME = "home"
export const MENU = "menu"
export const SPECIAL = "special"
export const DIM_SUM_MENU = "dim sum"
export const DRINKS = "drinks"

// Page Paths
export const HOME_PATH = "/"
export const MENU_PATH = "/menu/"
export const SPECIAL_PATH = "/special/"
export const DIM_SUM_MENU_PATH = "/dim_sum_menu/"
export const DRINK_MENU_PATH = "/drink_menu/"

// Nav Links
export const NAV_LINKS = [
  {
    name: HOME,
    Icon: HomeIcon,
    to: HOME_PATH,
  },
  {
    name: MENU,
    Icon: MenuIcon,
    to: MENU_PATH,
  },
  {
    name: SPECIAL,
    Icon: StarIcon,
    to: SPECIAL_PATH,
  },
  {
    name: DIM_SUM_MENU,
    Icon: DumplingIcon,
    to: DIM_SUM_MENU_PATH,
  },
  {
    name: DRINKS,
    Icon: DrinkIcon,
    to: DRINK_MENU_PATH,
  },
]

// Paths
export const PATHS = {
  [HOME_PATH]: HOME,
  [MENU_PATH]: MENU,
  [SPECIAL_PATH]: SPECIAL,
  [DIM_SUM_MENU_PATH]: DIM_SUM_MENU,
  [DRINK_MENU_PATH]: DRINKS,
}
