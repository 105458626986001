export default {
  items: [
    {
      name: "steamed chicken feet",
      image: "steamed-chicken-feet.jpg",
      price: "5.75",
    },
    {
      name: "steamed pork spareribs",
      image: "steamed-pork-spareribs.jpg",
      price: "5.75",
    },
    {
      name: "pan fried turnip cake",
      image: "pan-fried-turnip-cake.jpg",
      price: "5.75",
    },
    {
      name: "steamed b.b.q pork bun",
      image: "steamed-bbq-pork-bun.jpg",
      price: "5.75",
    },
    {
      name: "steamed salted egg bun",
      image: "steamed-salted-egg-bun.jpg",
      price: "5.75",
    },
    {
      name: "scallop dumpling",
      image: "scallop-dumpling.jpg",
      price: "5.75",
    },
    {
      name: "shrimp with sugarcane",
      image: "shrimp-with-surgarcane.jpg",
      price: "8.95",
    },
    {
      name: "steamed egg custard bun",
      image: "steamed-egg-custard-bun.jpg",
      price: "5.75",
    },
    {
      name: "pork dumpling (sui mai)",
      image: "pork-dumpling.jpg",
      price: "5.75",
    },
    {
      name: "crispy spring roll",
      image: "cripsy-spring-roll.jpg",
      price: "5.75",
    },
    {
      name: "sticky rice in lotus leaf",
      image: "sticky-rice-in-lotus-leaf.jpg",
      price: "5.75",
    },
    {
      name: "shrimp dumpling (har gaw)",
      image: "shrimp-dumpling.jpg",
      price: "5.75",
    },
    {
      name: "beef tripe w/ ginger & scallion",
      image: "beef-tripe-w-ginger-and-scallion.jpg",
      price: "5.75",
    },
    {
      name: "shanghai dumpling",
      image: "shanghai-dumpling.jpg",
      price: "5.75",
    },
    {
      name: "pan fried pot sticker",
      image: "pan-fried-pot-sticker.jpg",
      price: "5.75",
    },
    {
      name: "shrimp stuffed mushroom",
      image: "shrimp-stuffed-mushroom.jpg",
      price: "5.75",
    },
    {
      name: "shrimp stuffed eggplant",
      image: "shrimp-stuffed-eggplant.jpg",
      price: "5.75",
    },
    {
      name: "shrimp stuffed bell pepper",
      image: "shrimp-stuffed-bell-pepper.jpg",
      price: "5.75",
    },
    {
      name: "fried shrimp dumpling",
      image: "fried-shrimp-dumpling.jpg",
      price: "5.75",
    },
    {
      name: "shrimp w/ chives cake",
      image: "shrimp-w-chive-cake.jpg",
      price: "5.75",
    },
    {
      name: "pea sprout dumpling",
      image: "pea-sprout-dumpling.jpg",
      price: "5.75",
    },
    { name: "bean curd wrap", image: "bean-curd-wrap.jpg", price: "5.75" },
  ],
}
