import React from "react"

import { Container, Text } from "./SectionHeadingStyledComponents"

const SectionHeading = props => {
  const { children } = props

  return (
    <Container>
      <Text>{children}</Text>
    </Container>
  )
}

export default SectionHeading
