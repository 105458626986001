import styled from "styled-components/macro"

const Address = styled.address`
  text-align: center;
  margin: 16px 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const AddressContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
  padding: 0 16px;
`

const AddressText = styled.p`
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
`

const Container = styled.div`
  overflow: auto;
`

const DayAndTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  padding: 0 16px;
`

const FreeDeliveryContainer = styled.div`
  margin-top: 16px;

  & > p > span {
    font-weight: 600;
  }
`

const MapCanvas = styled.div`
  overflow: hidden;
  background: none !important;
  height: 400px;
  width: 100%;
`

const MapContainer = styled.div`
  position: relative;
  text-align: right;
  height: 400px;
  width: 100%;
`

const PhoneTextContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;
  padding: 0 16px;
`

const SectionContainer = styled.div`
  margin: 16px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SectionInformationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`

const Text = styled.p`
  padding: 0;
  margin: 0;
  font-style: normal;
`

export {
  Address,
  AddressContainer,
  AddressText,
  Container,
  DayAndTimeContainer,
  FreeDeliveryContainer,
  MapCanvas,
  MapContainer,
  PhoneTextContainer,
  SectionContainer,
  SectionInformationContainer,
  Text,
}
